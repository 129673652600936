<script>
import ListQuizResponse from '@shared/components/ListQuizResponse.vue';
import APITrainings from '@app/services/API/Trainings';
import quizMixin from '@app/mixins/quiz';

export default {
  mixins: [quizMixin],
  components: { ListQuizResponse },
  data: () => ({
    isOpen: false,
    isFetching: false,
    quizAnswers: null,
    quizAnswersIndex: 0,
  }),
  computed: {
    currentTraining() {
      return this.$store.state.trainings.current;
    },
    quizTrainingItem() {
      const id = Number(this.$route.params.id);
      return this.$store.getters['trainings/currentItemsFlat'].find((v) => v.id === id);
    },
    currentQuizResponse() {
      return this.quizAnswers[this.quizAnswersIndex];
    },
    isLastQuizAnswers() {
      return this.quizAnswersIndex >= this.quizAnswers.length - 1;
    },
  },
  created() {
    this.isFetching = true;
    APITrainings.getItemQuizAnswers(this.$route.params.uuid, this.$route.params.id)
      .then((data) => {
        this.quizAnswers = data.data;
      })
      .finally(() => (this.isFetching = false));
  },
  mounted() {
    this.isOpen = true;
  },
};
</script>

<template>
  <b-modal
    :active="isOpen"
    scroll="keep"
    @close="$router.push({name: 'training', params: {uuid: $route.params.uuid}})">
    <div class="box">
      <b-skeleton v-if="isFetching" height="400" />
      <div v-else-if="quizAnswers.length <= 0">
        Il n'y a pas encore de réponse à ce quiz
      </div>
      <template v-else>
        <nav class="level">
          <div class="level-left">
            <div>
              <h2 class="title is-5 mb-2">
                Réponses de :
                <span class="has-text-weight-normal">
                  {{ currentQuizResponse.customer.firstname }}
                  {{ currentQuizResponse.customer.lastname }}
                </span>
              </h2>
              <p>
                Le {{ currentQuizResponse.created_at | momentFromUTC | moment('DD/MM/YY')  }}
                •
                Taux de réussite : {{ currentQuizResponse.percentage_successful_questions }}%

                • <a
                  href="#"
                  @click.prevent="$options.staticExportQuizAnzswers(
                    quizTrainingItem.name,
                    currentTraining.uuid,
                    quizTrainingItem.id,
                    currentQuizResponse.customer
                  )">
                  Exporter ses réponses au quiz
                </a>
              </p>
            </div>
          </div>
          <div class="level-right">
            <p class="ml-2 is-size-6">
              {{ quizAnswersIndex + 1 }} / {{ quizAnswers.length }}
            </p>

            <b-button
              class="ml-2"
              icon-left="chevron-left"
              :disabled="quizAnswersIndex <= 0"
              @click="quizAnswersIndex--" />
            <b-button
              class="ml-2"
              icon-left="chevron-right"
              :disabled="isLastQuizAnswers"
              @click="quizAnswersIndex++" />
          </div>
        </nav>
        <hr>
        <ListQuizResponse :quizResponse="currentQuizResponse" />
      </template>
    </div>
  </b-modal>
</template>
